import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { AnyAccessType, Consumer } from 'src/app/shared/models/consumers';
import { Order } from 'src/app/shared/models/orders';

import { ManagableItem, UnknownManagableItem } from '../../shared/models/misc';
import {
  RepeatOrderChangedData,
  RepeatOrderOptionData,
} from '../../shared/models/orders';
import { Menu } from './../../shared/models/menus';
import {
  FetchOrdersParams,
  ManagableOrder,
  ProductSheetLoadingState,
  RepeatOrder,
  SaveOrderButtonState,
  SetOrdersItem,
} from './../../shared/models/orders';
import { UnauthConsumer, User } from 'src/app/shared/models/user';

export const clearOrdersPage = createAction(
  `[Orders API] Clear stored orders data`,
);

export const fetchSource = createAction(
  `[Orders API] Fetch source of the current menu`,
  props<{
    menu: Menu;
    dietRefetch?: boolean;
    scheduledDiets?: string[];
    nonRegularTranslation?: string;
  }>(),
);

export const fetchOrders = createAction(
  `[Orders Container] Fetch orders for specific menu and date`,
  props<Partial<FetchOrdersParams>>(),
);

export const setOrders = createAction(
  `[Orders Container] Set orders after fetching them`,
  props<{ orders: Order[] }>(),
);

export const setSource = createAction(
  `[Orders API] Set source of the menu`,
  props<{ source: string | null }>(),
);

export const sendOrdersAndRedirect = createAction(
  `[Orders Container] Send selected orders and then redirect to orders complete`,
  props<{
    diets?: number[];
    items: SetOrdersItem[];
    editedItem?: ManagableItem;
    previousOrders: Order[] | null;
  }>(),
);

export const cancelOrders = createAction(
  `[Complete orders container] Cancel orders`,
  props<{
    editedItem?: ManagableItem;
    orders: Order[];
  }>(),
);

export const setSaveOrderButtonState = createAction(
  `[Orders Container] Set save order button state`,
  props<SaveOrderButtonState>(),
);

export const sendOrders = createAction(
  `[Orders Container] Send selected orders`,
  props<{
    diets: string[];
    items: SetOrdersItem[];
    editedItem?: ManagableItem;
    navigationTarget?: string[];
    ignoreSetOrders?: boolean;
    previousOrders: Order[] | null;
  }>(),
);

export const sendOrdersAndNext = createAction(
  `[Orders Container] Send selected orders and go to the next menu`,
  props<{
    diets?: number[];
    items: SetOrdersItem[];
    editedItem?: ManagableItem;
    previousOrders: Order[] | null;
  }>(),
);

export const sendOrdersAndPrev = createAction(
  `[Orders Container] Send selected orders and go to the previous menu`,
  props<{
    diets?: number[];
    items: SetOrdersItem[];
    editedItem?: ManagableItem;
    previousOrders: Order[] | null;
  }>(),
);

export const redirectToOrdersComplete = createAction(
  `[Orders Container] Redirect to orders/complete after orders were sent`,
  props<{ menu: string; date: string }>(),
);

export const fetchOrdersOfConsumer = createAction(
  `[Live order view] Fetch orders`,
  props<{ rfid: string }>(),
);

export const ordersSelectionStateChanged = createAction(
  `[Orders container] Selection of orders has changed`,
  props<{ state: boolean }>(),
);

export const fetchProductSheet = createAction(
  `[Orders container] fetch product sheet`,
  props<{ token: string }>(),
);

export const setProductSheetSource = createAction(
  `[Orders container] Set source of Product sheet`,
  props<{ productSheetSource: string }>(),
);

export const setProductSheetLoading = createAction(
  `[Orders Container] set product sheet loading`,
  props<ProductSheetLoadingState>(),
);

export const fetchManagableItem = createAction(
  `[Orders API] Fetch user or consumer whose order is being edited`,
  props<{ created_by?: number; consumer?: number }>(),
);

export const repeatOrderChanged = createAction(
  '[Complete orders container] Repeat option of an order was selected',
  props<{
    repeatOrderOption: RepeatOrderOptionData;
    order?: Order;
    repeatOrder?: RepeatOrder;
    url: string | null;
    menu: string;
  }>(),
);

export const createRepeatOrder = createAction(
  '[Complete orders container] Create new repeat order object',
  props<{
    createRepeatOrderData: RepeatOrderChangedData;
    repeatOrder: RepeatOrder;
  }>(),
);

export const createRepeatOrderFromOrder = createAction(
  '[Complete orders container] Create new repeat order object from order',
  props<{ createRepeatOrderData: RepeatOrderChangedData; order: Order }>(),
);

export const setCreatedRepeatOrder = createAction(
  '[Complete orders container] Set created repeat order object locally',
  props<{ repeatOrder: RepeatOrder }>(),
);

export const setUpdatedRepeatOrder = createAction(
  '[Complete orders container] Set updated repeat order object locally',
  props<{ repeatOrder: Update<RepeatOrder> }>(),
);

export const setDeletedRepeatOrder = createAction(
  '[Complete orders container] Set deleted repeat order object locally',
  props<{ id: number }>(),
);

export const changeRepeatOrder = createAction(
  '[Complete orders container] Change repeat order object',
  props<{ changeRepeatOrderData: RepeatOrderChangedData; url: string }>(),
);

export const deleteRepeatOrder = createAction(
  '[Complete orders container] Delete repeat order object',
  props<{ url: string; id: number }>(),
);

export const fetchRepeatOrders = createAction(
  '[Orders API] Fetch orders repeat',
  props<Partial<FetchOrdersParams>>(),
);

export const repeatOrdersFetched = createAction(
  '[Orders API] Repeat orders were fetched',
  props<{ repeatOrders: RepeatOrder[] }>(),
);

export const setRepeatOrdersManage = createAction(
  '[Orders API] Set repeat orders (manage) locally',
  props<{ repeatOrders: RepeatOrder[] }>(),
);

export const setGeneralRepeatOrders = createAction(
  '[Orders API] Set or delete general repeat orders',
  props<{ repeatOrderOptionData: RepeatOrderOptionData }>(),
);

export const generalRepeatOrdersFetched = createAction(
  '[Orders API] General repeat orders were fetched',
  props<{ repeatOrders: RepeatOrder[] }>(),
);

export const applyDefaultOrder = createAction(
  '[Orders container] Apply default order',
  props<{
    orderData: AnyAccessType | ManagableOrder;
    selectedDiets: string[];
  }>(),
);

export const setMenuDiets = createAction(
  '[Orders container] Set menu diet',
  props<{ selectedDiets: string[] }>(),
);

export const setManagableItem = createAction(
  `[Orders/Menus API] Set extras before redirecting to other route`,
  props<{ item: UnknownManagableItem }>(),
);

export const setMenuLanguage = createAction(
  '[Orders container] Set menu language locally',
  props<{ language: string }>(),
);

export const fetchOrdersCount = createAction(
  '[Orders container] Fetch orders count',
  props<{ date: string }>(),
);

export const setOrdersCount = createAction(
  '[Orders container] Set orders count',
  props<{ count: number }>(),
);

export const setOrdersDoneCount = createAction(
  '[Orders container] Set orders done count',
  props<{ count: number }>(),
);

export const setOrdersCountIncrement = createAction(
  '[Orders container] Set orders count increment',
);

export const updateRepeatOrders = createAction(
  '[Orders container] Change repeated orders locally',
  props<{ repeatOrders: RepeatOrder[] }>(),
);

export const resetDeleteRealTimeOrders = createAction(
  '[Orders container] Reset delete orders in real-time',
  props<{
    orders: Order[];
    date: string;
    unauthConsumer: UnauthConsumer;
    terminalConsumer: Consumer;
    item: ManagableItem;
    consumer: number;
    createdBy: number;
    currentUser: User;
  }>(),
);
