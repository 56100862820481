import { Action, createReducer, on } from '@ngrx/store';
import { SimpleAuthenticationParams } from 'src/app/shared/models/authentication';
import { InvitationResult } from 'src/app/shared/models/user';
import * as AuthenticationActions from './authentication.actions';

export const authenticationFeatureKey = 'authentication';

export const initialState = null;

export interface AuthenticationState {
  isLogged: boolean;
  isLoggedIntoTerminal: boolean;
  unauthSpinner: boolean;
  unauthAggregatedOrderFeature: boolean;
  unauthInvalidUrlMessage: boolean;
  login_spinner: boolean;
  reset_spinner: boolean;
  register_spinner: boolean;
  confirmEmailLoading: boolean;
  confirmEmailSuccessOrFail: boolean | undefined;
  roomLoginBar: boolean;
  roomDetailsLoadingBar: boolean;
  invitationError: string;
  invitationResult: InvitationResult;
  authKeyAndId: SimpleAuthenticationParams;
  redirectionUrl: string;
}

const _authenticationReducer = createReducer<AuthenticationState>(
  initialState,
  on(AuthenticationActions.setLoginButtonState, (state, { login_spinner }) => ({
    ...state,
    login_spinner,
  })),
  on(AuthenticationActions.setResetButtonState, (state, { reset_spinner }) => ({
    ...state,
    reset_spinner,
  })),
  on(
    AuthenticationActions.setRegisterButtonState,
    (state, { register_spinner }) => ({
      ...state,
      register_spinner,
    }),
  ),
  on(AuthenticationActions.setLogin, (state, { isLogged }) => ({
    ...state,
    isLogged,
  })),
  on(AuthenticationActions.setAuthKeyAndId, (state, { payload }) => ({
    ...state,
    authKeyAndId: payload,
  })),
  on(AuthenticationActions.setProposedEmail, (state, { email }) => ({
    ...state,
    proposedEmail: email,
  })),
  on(
    AuthenticationActions.setConfirmEmailLoading,
    (state, { payload: loadingState }) => ({
      ...state,
      confirmEmailLoading: loadingState,
    }),
  ),
  on(
    AuthenticationActions.setConfirmEmailSuccessOrFail,
    (state, { payload }) => ({
      ...state,
      confirmEmailSuccessOrFail: payload,
    }),
  ),
  on(
    AuthenticationActions.setLoggedIntoTerminal,
    (state, { isLoggedIntoTerminal }) => ({
      ...state,
      isLoggedIntoTerminal,
    }),
  ),
  on(AuthenticationActions.roomLoginBar, (state, { payload }) => ({
    ...state,
    roomLoginBar: payload,
  })),
  on(AuthenticationActions.roomDetailsLoadingBar, (state, { payload }) => ({
    ...state,
    roomDetailsLoadingBar: payload,
  })),
  on(AuthenticationActions.setInvitationError, (state, { message }) => ({
    ...state,
    invitationError: message,
  })),
  on(AuthenticationActions.setInvitationResult, (state, { payload }) => ({
    ...state,
    invitationResult: payload,
  })),
  on(AuthenticationActions.setUnauthSpinner, (state, { value }) => ({
    ...state,
    unauthSpinner: value,
  })),
  on(
    AuthenticationActions.setUnauthAggregatedOrderFeature,
    (state, { enable }) => ({
      ...state,
      unauthAggregatedOrderFeature: enable,
    }),
  ),
  on(AuthenticationActions.setUnauthInvalidUrlMessage, (state, { show }) => ({
    ...state,
    unauthInvalidUrlMessage: show,
  })),
  on(AuthenticationActions.setRedirectionUrl, (state, { url }) => ({
    ...state,
    redirectionUrl: url,
  })),
);

export function authenticationReducer(
  state: AuthenticationState,
  action: Action,
) {
  return _authenticationReducer(state, action);
}
