import { Action, createReducer, on } from '@ngrx/store';

import { Menu } from '../../shared/models/menus';
import {
  cleanCurrentMenu,
  cleanMenus,
  setSelectedDate,
  patchCurrentMenu,
  resetMenusReducer,
  setCurrentMenu,
  setCurrentMenuAndRedirect,
  setMenus,
  setNextMenu,
  setPrevMenu,
} from './menus.actions';

export const menusFeatureKey = 'menus';

export interface MenusState {
  menus: Menu[];
  currentMenu: Menu;
  nextMenu: Menu;
  prevMenu: Menu;
  selectedDate: string;
}

export const initialState = {
  menus: null,
  currentMenu: null,
  nextMenu: null,
  prevMenu: null,
  selectedDate: null,
};

const _menusReducer = createReducer<MenusState>(
  initialState,
  on(setSelectedDate, (state, { date }) => ({ ...state, selectedDate: date })),
  on(setMenus, (state, { menus }) => {
    if (state?.currentMenu && state?.currentMenu.show_date && menus?.length) {
      const filteredMenus = menus
        ?.filter((menu) => menu.show_date)
        .sort((a, b) => {
          const dateComparison = a.date.localeCompare(b.date);
          if (dateComparison !== 0) return dateComparison;
          return a.meal_sorting - b.meal_sorting;
        });
      const currentMenuId = filteredMenus?.findIndex(
        (menu) => menu.id === state?.currentMenu?.id,
      );
      const nextMenu = filteredMenus?.[currentMenuId + 1] ?? null;
      const prevMenu = filteredMenus?.[currentMenuId - 1] ?? null;
      return {
        ...state,
        menus,
        nextMenu,
        prevMenu,
      };
    }
    return {
      ...state,
      menus,
    };
  }),
  on(setCurrentMenuAndRedirect, (state, { menu }) => ({
    ...state,
    currentMenu: menu,
  })),
  on(setCurrentMenu, (state, { menu: currentMenu }) => {
    if (currentMenu?.show_date && state.menus?.length) {
      const filteredMenus = state.menus
        ?.filter((menu) => menu.show_date)
        .sort((a, b) => {
          const dateComparison = a.date.localeCompare(b.date);
          if (dateComparison !== 0) return dateComparison;
          return a.meal_sorting - b.meal_sorting;
        });
      const currentMenuId = filteredMenus?.findIndex(
        (menu) => menu.id === currentMenu?.id,
      );
      const nextMenu = filteredMenus?.[currentMenuId + 1] ?? null;
      const prevMenu = filteredMenus?.[currentMenuId - 1] ?? null;
      return {
        ...state,
        currentMenu,
        nextMenu,
        prevMenu,
      };
    }
    return {
      ...state,
      currentMenu,
      nextMenu: null,
      prevMenu: null,
    };
  }),
  on(cleanCurrentMenu, (state) => ({ ...state, currentMenu: undefined })),
  on(cleanMenus, (state) => ({ ...state, menus: undefined })),
  on(patchCurrentMenu, (state, { id, menu: currentMenu }) => ({
    ...state,
    menus: state.menus
      ? state.menus.map((menu) => {
          if (menu.id === id) return { ...menu, ...currentMenu };
          return menu;
        })
      : null,
    currentMenu: state.currentMenu
      ? { ...state.currentMenu, ...currentMenu }
      : undefined,
  })),
  on(resetMenusReducer, () => initialState),
  on(setPrevMenu, (state, { menu: prevMenu }) => ({ ...state, prevMenu })),
  on(setNextMenu, (state, { menu: nextMenu }) => ({ ...state, nextMenu })),
);

export function menusReducer(state: MenusState, action: Action) {
  return _menusReducer(state, action);
}
