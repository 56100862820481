import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MenusState, menusFeatureKey } from './menus.reducer';

export const selectState = createFeatureSelector<MenusState>(menusFeatureKey);

export const selectMenus = createSelector(selectState, (menus) => menus?.menus);

export const selectDailyMenus = createSelector(selectMenus, (menus) =>
  menus?.filter((menu) => menu.date),
);

export const selectSpecialMenus = createSelector(selectMenus, (menus) =>
  menus?.filter((menu) => menu.date === null),
);

export const selectCurrentMenu = createSelector(
  selectState,
  (menus) => menus?.currentMenu,
);

export const selectPickedDate = createSelector(
  selectState,
  (menus) => menus?.selectedDate,
);

export const selectPrevMenu = createSelector(
  selectState,
  (menus) => menus?.prevMenu,
);

export const selectNextMenu = createSelector(
  selectState,
  (menus) => menus?.nextMenu,
);
