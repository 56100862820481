import { DOCUMENT, KeyValuePipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  Injector,
  Input,
  OnDestroy,
  Renderer2,
  ViewChild,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  ControlContainer,
  ControlValueAccessor,
  FormControl,
  FormControlDirective,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MatDatepickerInput,
  MatDatepickerInputEvent,
  MatDatepickerModule,
} from '@angular/material/datepicker';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';

import { UtilsService } from '../../services/utils.service';
import { InterfaceLanguage, LANGUAGES } from './../../constants/languages';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { ServerFieldErrorDirective } from '../../directives/server-field-error.directive';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';

type InputType =
  | 'text'
  | 'password'
  | 'email'
  | 'select'
  | 'date'
  | 'time'
  | 'checkbox'
  | 'textarea'
  | 'number';

@Component({
  selector: 'win-field-with-errors',
  templateUrl: './field-with-errors.component.html',
  styleUrls: ['./field-with-errors.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FieldWithErrorsComponent,
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    KeyValuePipe,
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatOptionModule,
    MatProgressBarModule,
    MatSelectModule,
    ReactiveFormsModule,
    ServerFieldErrorDirective,
    TranslocoPipe,
  ],
})
export class FieldWithErrorsComponent
  implements AfterViewInit, ControlValueAccessor, OnDestroy
{
  private readonly document = inject(DOCUMENT);
  private readonly changeDetectorRef = inject(ChangeDetectorRef);
  private readonly destroyRef = inject(DestroyRef);
  private readonly injector = inject(Injector);
  private readonly renderer = inject(Renderer2);
  private readonly utilsService = inject(UtilsService);

  @Input() autofocus = false;
  @Input() autoclear = false;
  @Input() autocomplete: string;
  @Input() formControlName: string;
  @Input() icon: string;
  @Input() label: string;
  @Input() lang: InterfaceLanguage;
  @Input() multiple = false;
  @Input() optionLabel: string;
  @Input() optionLabelMulti;
  @Input() placeholder: string;
  @Input() required = false;
  @Input() selectOptions: any[];
  @Input() subscriptSizing: 'fixed' | 'dynamic' = 'fixed';
  @Input() showProgressBar: boolean;
  @Input() suffixIcon: string;
  @Input() translationKey: string;
  @Input() type: InputType;
  @Input() valueField: string;

  AUTOCLEAR_DELAY = 5000;
  _datepickerInput: MatDatepickerInput<Date>;
  private clearTimer: any;
  private focusUnlistener: () => void;
  private autofocusEnabled = false;

  @ViewChild('datepickerInput', { read: MatDatepickerInput })
  set datepickerInput(value: MatDatepickerInput<Date>) {
    this._datepickerInput = value;
  }
  @ViewChild(FormControlDirective, { static: false })
  formControlDirective: FormControlDirective;
  @ViewChild('inputElement', { static: false })
  inputElement: ElementRef<HTMLInputElement>;

  get controlContainer() {
    return this.injector.get(ControlContainer);
  }

  get control(): FormControl {
    return this.controlContainer?.control?.get(
      this.formControlName,
    ) as FormControl;
  }

  constructor() {
    this.utilsService.enableAutofocus
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((state) => (this.autofocusEnabled = state));
  }

  ngAfterViewInit(): void {
    if (this.focusUnlistener) this.focusUnlistener();
    if (this.autofocus && this.autofocusEnabled) {
      this.focusUnlistener = this.renderer.listen(this.document, 'focus', () =>
        this.focusInput(),
      );

      this.focusInput();
    }
    this.controlContainer.control.statusChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        setTimeout(() => {
          this.changeDetectorRef.detectChanges();
        }, 0);
      });
  }

  private focusInput(): void {
    if (this.inputElement.nativeElement) {
      this.renderer.selectRootElement(this.inputElement.nativeElement).focus();
    }
  }

  changeDate({ value }: MatDatepickerInputEvent<Date>): void {
    this.control.setValue(value);
  }

  blur(event: FocusEvent): void {
    if (this.autofocus && this.autofocusEnabled) {
      const inputElement = event?.target as HTMLInputElement;
      requestAnimationFrame(() => inputElement?.focus());
    }
  }

  getFallbackLang = (option: any) =>
    option?.[
      Object.keys(option).filter(
        (k) => Object.keys(LANGUAGES).includes(k) && option[k],
      )?.[0] || this.lang
    ] ?? '';

  onInputChange(): void {
    // Clear any existing timers
    if (this.clearTimer) window.clearTimeout(this.clearTimer);

    // Set a new timer if autoclear is true
    if (this.autoclear) {
      this.clearTimer = setTimeout(() => {
        this.control.reset();
        this.control.setErrors(null);
        this.control.markAsPristine();
        this.control.markAsUntouched();
      }, this.AUTOCLEAR_DELAY);
    }
  }

  openDatepicker(): void {
    this._datepickerInput?._datepicker.open();
  }

  parseInt = (value: string) => parseInt(value);

  registerOnChange(fn: any): void {
    this.formControlDirective?.valueAccessor.registerOnChange(fn);
  }

  registerOnTouched(fn: any): void {
    this.formControlDirective?.valueAccessor.registerOnTouched(fn);
  }

  selectionChange(event: MatSelectChange): void {
    this.control.setValue(event?.value, { emitEvent: false });
  }

  setDisabledState(isDisabled: boolean): void {
    this.formControlDirective?.valueAccessor.setDisabledState(isDisabled);
  }

  writeValue(obj: any): void {
    this.formControlDirective?.valueAccessor.writeValue(obj);
  }

  ngOnDestroy(): void {
    if (this.focusUnlistener) this.focusUnlistener();
    if (this.clearTimer) clearTimeout(this.clearTimer);
  }
}
