import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  OrganisationFeatures,
  TerminalType,
  User,
} from 'src/app/shared/models/user';
import { Organisation } from '../../shared/models/user';
import { UnauthConsumer } from './../../shared/models/user';
import { UserState, userFeatureKey } from './user.reducer';

export const selectState = createFeatureSelector<UserState>(userFeatureKey);

export const selectUser = createSelector(
  selectState,
  (state) => state?.currentUser,
);

export const selectSimpleConsumer = createSelector(
  selectState,
  (state) => state?.simpleConsumer,
);

export const selectConsumer = createSelector(
  selectState,
  (state) => state?.currentConsumer,
);

export const roomDetail = createSelector(
  selectState,
  (state) => state?.roomDetail,
);

export const selectPersonalReports = createSelector(
  selectState,
  (state) => state?.reports,
);

export const selectConsumersReports = createSelector(
  selectState,
  (state) => state?.reportsConsumers,
);

export const selectOrdersReports = createSelector(
  selectState,
  (state) => state?.reportsOrders,
);

export const selectDownloadingReports = createSelector(
  selectState,
  (state) => state?.downloading,
);

export const selectTerminal = createSelector(
  selectUser,
  (user: User) => user?.terminal,
);

export const selectIsTerminal = createSelector(
  selectUser,
  (user: User) => !!user?.terminal,
);

export const selectOrganisation = createSelector(
  selectUser,
  selectSimpleConsumer,
  (user, consumer) => user?.organisation || consumer?.organisation,
);

export const selectLogo = createSelector(
  selectUser,
  selectOrganisation,
  (user: User, organisation: Organisation) =>
    user?.location && user?.location_detail?.logo
      ? user?.location_detail?.logo
      : organisation?.logo,
);

export const selectEmailVerified = createSelector(
  selectUser,
  (user: User) => user?.status?.email_verified,
);

export const selectUserFullName = createSelector(selectUser, (user: User) =>
  user ? `${user?.first_name} ${user?.last_name}` : undefined,
);

export const selectUserLocation = createSelector(selectUser, (user: User) =>
  user ? user?.location : undefined,
);

// FIXME: this should depend on the consumer/user in question, not the user using the app (unless unauth)
export const selectUserAllowOrderAllergies = createSelector(
  selectUser,
  selectOrganisation,
  (user: User, organisation: Organisation) =>
    user?.location
      ? user?.location_detail?.allow_order_allergies
      : organisation?.allow_order_allergies,
);

// FIXME: this should depend on the consumer/user in question, not the user using the app (unless unauth)
export const selectUserAllowOrderIntolerances = createSelector(
  selectUser,
  selectOrganisation,
  (user: User, organisation: Organisation) =>
    user?.location
      ? user?.location_detail?.allow_order_intolerances
      : organisation?.allow_order_intolerances,
);

export const selectShowQuantity = createSelector(
  selectSimpleConsumer,
  selectUser,
  selectOrganisation,
  (simpleConsumer: UnauthConsumer) =>
    !!simpleConsumer &&
    (simpleConsumer?.location_detail
      ? simpleConsumer?.location_detail?.unauth_consumers_quantity_selection
      : simpleConsumer?.organisation?.unauth_consumers_quantity_selection),
);

export const selectShowPortionSize = createSelector(
  selectSimpleConsumer,
  selectUser,
  selectOrganisation,
  (simpleConsumer: UnauthConsumer) =>
    !!simpleConsumer &&
    (simpleConsumer?.location_detail
      ? simpleConsumer?.location_detail?.unauth_consumers_portion_size_selection
      : simpleConsumer?.organisation?.unauth_consumers_portion_size_selection),
);

export const selectQuantityInputField = createSelector(
  selectSimpleConsumer,
  selectUser,
  (simpleConsumer, user) => {
    if (simpleConsumer) {
      return simpleConsumer.location_detail
        ? simpleConsumer.location_detail.quantity_input_field
        : simpleConsumer.organisation.quantity_input_field;
    }
    return user?.location
      ? user.location_detail.quantity_input_field
      : user?.organisation.quantity_input_field;
  },
);

export const selectQuantityFieldIncrement = createSelector(
  selectSimpleConsumer,
  selectUser,
  (simpleConsumer, user) => {
    if (simpleConsumer) {
      return simpleConsumer.location_detail
        ? simpleConsumer.location_detail.quantity_input_field_increment
        : simpleConsumer.organisation.quantity_input_field_increment;
    }
    return user?.location
      ? user.location_detail.quantity_input_field_increment
      : user?.organisation.quantity_input_field_increment;
  },
);

export const selectConsumerSorting = createSelector(
  selectUser,
  (user: User) => user?.settings?.sorting_consumers,
);

export const selectUsersSorting = createSelector(
  selectUser,
  (user: User) => user?.settings?.sorting_users,
);

export const seletOrdersSorting = createSelector(
  selectUser,
  (user: User) => user?.settings?.sorting_orders,
);

export const selectuserColumns = createSelector(
  selectUser,
  (user: User) => user?.settings?.columns_users,
);

export const selectOrderColumns = createSelector(
  selectUser,
  (user: User) => user?.settings?.columns_orders,
);

export const selectConsumerColumns = createSelector(
  selectUser,
  (user: User) => user?.settings?.columns_consumers,
);

export const selectConsumerId = createSelector(
  selectUser,
  (user: User) => user?.id,
);

export const selectUserEmail = createSelector(
  selectUser,
  (user: User) => user?.email,
);

export const selectIsConsumer = createSelector(selectUser, (user: User) =>
  [1].includes(user?.role),
);

export const selectIsManager = createSelector(selectUser, (user: User) =>
  [2, 3].includes(user?.role),
);

export const selectIsAgent = createSelector(selectUser, (user: User) =>
  [6].includes(user?.role),
);

export const selectIsManagerOrAgent = createSelector(selectUser, (user: User) =>
  [2, 3, 6].includes(user?.role),
);

export const selectIsManagerOrAgentUser = createSelector(
  selectUser,
  (user: User) => [2, 3, 6].includes(user?.role) && !user?.terminal,
);

export const selectIsManagerUser = createSelector(
  selectUser,
  (user: User) => [2, 3].includes(user?.role) && !user?.terminal,
);

export const selectIsKdsUser = createSelector(
  selectUser,
  (user: User) => user?.is_kds,
);

export const selectIsServiceUser = createSelector(
  selectUser,
  (user: User) => user?.is_service,
);

export const selectIsAdmin = createSelector(selectUser, (user: User) =>
  [2].includes(user?.role),
);

export const selectUserSettingsLanguage = createSelector(
  selectUser,
  (user) => user?.settings?.language,
);

export const selectUserStatusOrderUntil = createSelector(
  selectUser,
  (user) => user?.status?.orderby_until,
);

export const selectLiveOrderView = createSelector(
  selectUser,
  (user) => user?.terminal?.type === TerminalType.CANTEEN,
);

export const selectAllowOfflineMode = createSelector(
  selectIsManager,
  selectIsTerminal,
  selectLiveOrderView,
  (isManager, isTerminal, isCanteenTerminal) =>
    isManager && isTerminal && !isCanteenTerminal,
);

export const selectSupportedCustomData = createSelector(
  selectOrganisation,
  (org) => org?.supported_custom_data,
);

export const selectSupportedCustomDataConsumers = createSelector(
  selectOrganisation,
  (org) => org?.supported_custom_data_consumers,
);

export const selectFeature = (feature: OrganisationFeatures) =>
  createSelector(selectOrganisation, (org) =>
    org?.enabled_features?.includes(feature),
  );

export const selectShowThirdMenuCard = createSelector(selectUser, (user) => {
  return (
    user?.organisation?.enabled_features?.includes(
      OrganisationFeatures.staff_ordertaking,
    ) && [2, 3].includes(user?.role)
  );
});

export const selectEnabledReports = createSelector(
  selectOrganisation,
  (org) => org?.enabled_reports,
);

export const selectEnabledFeatures = createSelector(
  selectOrganisation,
  (org) => org?.enabled_features,
);

export const selectJoinedDate = createSelector(
  selectOrganisation,
  (org) => org?.date_joined,
);

export const selectReport = (report: string) =>
  createSelector(selectOrganisation, (org) =>
    org?.enabled_reports?.includes(report),
  );

export const selectDisabledRepeatOptions = createSelector(
  selectUser,
  selectOrganisation,
  (user: User, organisation: Organisation) =>
    user?.location && user?.location_detail?.disabled_repetition_modes
      ? user?.location_detail?.disabled_repetition_modes
      : organisation?.disabled_repetition_modes,
);

export const selectOrderUntil = createSelector(
  selectUser,
  selectOrganisation,
  (user: User, organisation: Organisation) =>
    user?.location && user?.location_detail?.order_days_until
      ? user?.location_detail?.order_days_until
      : organisation?.order_days_until,
);

export const selectManageOrdersDefaultDays = createSelector(
  selectUser,
  selectOrganisation,
  (user: User, organisation: Organisation) =>
    user?.location && user?.location_detail?.manage_orders_default_days
      ? user?.location_detail?.manage_orders_default_days
      : organisation?.manage_orders_default_days,
);

export const selectDefaultManageAccessesStatus = createSelector(
  selectUser,
  selectOrganisation,
  (user: User, organisation: Organisation) =>
    user?.location && user?.location_detail?.manage_users_default_filter
      ? user?.location_detail?.manage_users_default_filter
      : organisation?.manage_users_default_filter,
);

export const selectDefaultManageConsumersStatus = createSelector(
  selectUser,
  selectOrganisation,
  (user: User, organisation: Organisation) =>
    user?.location && user?.location_detail?.manage_consumers_default_filter
      ? user?.location_detail?.manage_consumers_default_filter
      : organisation?.manage_consumers_default_filter,
);

export const selectRealTimeOrders = createSelector(
  selectUser,
  selectOrganisation,
  (user: User, organisation: Organisation) =>
    user?.location
      ? user?.location_detail?.real_time_orders
      : organisation?.real_time_orders,
);

export const selectAllergensExtended = createSelector(
  selectOrganisation,
  (organisation: Organisation) => organisation?.allergens_extended,
);

export const selectAllergensExtendedLactose = createSelector(
  selectOrganisation,
  (organisation: Organisation) => organisation?.allergens_lac_extended,
);
