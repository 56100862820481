import { Action, createReducer, on } from '@ngrx/store';
import { Consumer } from 'src/app/shared/models/consumers';
import {
  Report,
  RoomConsumer,
  UnauthConsumer,
  User,
} from 'src/app/shared/models/user';

import {
  removeReportDownloading,
  setAvailableReports,
  setConsumer,
  setReportDownloading,
  setRoomDetail,
  setUnauthConsumer,
  setUser,
} from './user.actions';

export const userFeatureKey = 'user';

export interface UserState {
  currentUser: User;
  currentConsumer: Consumer;
  simpleConsumer: UnauthConsumer;
  roomDetail: RoomConsumer[] | { message: string };
  reports: Report[];
  reportsConsumers: Report[];
  reportsOrders: Report[];
  downloading: string[];
}

export const initialState: UserState = {
  currentUser: null,
  currentConsumer: null,
  simpleConsumer: null,
  roomDetail: null,
  reports: null,
  reportsConsumers: null,
  reportsOrders: null,
  downloading: [],
};

const _currentUserReducer = createReducer(
  initialState,
  on(setUser, (state, { payload }) => ({ ...state, currentUser: payload })),
  on(setConsumer, (state, { payload }) => ({
    ...state,
    currentConsumer: payload,
  })),
  on(setRoomDetail, (state, { payload }) => ({
    ...state,
    roomDetail: payload,
  })),
  on(setUnauthConsumer, (state, { simpleConsumer }) => ({
    ...state,
    simpleConsumer,
  })),
  on(setAvailableReports, (state, { reports }) => ({
    ...state,
    reports: reports.filter((report) => report.type === 'personal'),
    reportsConsumers: reports.filter((report) => report.type === 'consumers'),
    reportsOrders: reports.filter((report) => report.type === 'orders'),
  })),
  on(setReportDownloading, (state, { report }) => ({
    ...state,
    downloading: [...state.downloading, report.name],
  })),
  on(removeReportDownloading, (state, { report }) => ({
    ...state,
    downloading: state.downloading.filter((name) => name !== report.name),
  })),
);

export function userReducer(state: UserState, action: Action) {
  return _currentUserReducer(state, action);
}
