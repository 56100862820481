import { Action, createReducer, on } from '@ngrx/store';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import { FormErrors } from 'src/app/shared/models/globals';
import * as GlobalActions from './global.actions';

export const globalFeatureKey = 'global';

export interface GlobalState {
  language: InterfaceLanguage;
  snackbarLastError: string;
  formErrors: FormErrors;
  global_error: string;
  autocompleteSearch: string[];
}

export const initialState = null;

const _globalReducer = createReducer<GlobalState>(
  initialState,
  on(GlobalActions.setGlobalLanguage, (state, { payload: language }) => ({
    ...state,
    language,
  })),
  on(GlobalActions.addFormError, (state, { error }) => {
    const errors = state?.formErrors;
    const nonFieldErrors = errors?.non_field_errors;
    let newErrors: FormErrors;

    if (!errors) newErrors = {};
    else newErrors = { ...errors };

    if (!nonFieldErrors) newErrors.non_field_errors = [error];
    else newErrors.non_field_errors = [...nonFieldErrors, error];

    return {
      ...state,
      formErrors: newErrors,
    };
  }),
  on(GlobalActions.setFormErrors, (state, { payload }) => ({
    ...state,
    formErrors: { ...state.formErrors, ...payload },
  })),
  on(GlobalActions.clearFormErrors, (state, { payload: errorName }) => ({
    ...state,
    formErrors: {
      ...state?.formErrors,
      [errorName]: undefined,
    },
  })),
  on(GlobalActions.setAutocompleteSearch, (state, { results }) => ({
    ...state,
    autocompleteSearch: results,
  })),
  on(GlobalActions.setGlobalError, (state, { error }) => ({
    ...state,
    global_error: error,
  })),
);

export function globalReducer(state: GlobalState, action: Action) {
  return _globalReducer(state, action);
}
