import { createAction, props } from '@ngrx/store';
import { ManagableAccess } from 'src/app/shared/models/consumers';

import { FetchMenusParams, Menu } from '../../shared/models/menus';
import { UnknownManagableItem } from '../../shared/models/misc';

export const fetchMenus = createAction(
  `[Menus API] Fetch menus`,
  props<{ params?: FetchMenusParams }>(),
);

export const fetchMenu = createAction(
  `[Orders container] Fetch specific menu`,
  props<{ params?: FetchMenusParams; fetchRepeatOrders?: boolean }>(),
);

export const setMenus = createAction(
  `[Menus API] Save menus to store after successfuly fetching them`,
  props<{ menus: Menu[] }>(),
);

export const menuSelected = createAction(
  `[Menus container] A menu was selected`,
  props<{ menu: Menu; item?: UnknownManagableItem; date?: string }>(),
);

export const setCurrentMenuAndRedirect = createAction(
  `[Menus container] Set current menu and redirect to orders page`,
  props<{ menu: Menu; item?: UnknownManagableItem; date?: string }>(),
);

export const setCurrentMenu = createAction(
  `[Menus container] Set current menu`,
  props<{ menu: Menu; date?: string }>(),
);

export const redirectToOrders = createAction(
  `[Menus container] Redirect to orders (or orders complete) after a menu was selected`,
  props<{ complete?: boolean }>(),
);

export const cleanCurrentMenu = createAction(`[Menus API] Clean current menu`);

export const resetMenusReducer = createAction(
  `[Menus API] Reset menus reducer`,
);

export const cleanMenus = createAction(`[Menus API] Clean menus`);

export const setSelectedDate = createAction(
  `[Menus container] Date was selected with datepicker`,
  props<{ date: string }>(),
);

export const patchCurrentMenu = createAction(
  `[Menus API] Update current menu`,
  props<{ id: number; menu: Partial<Menu> }>(),
);

export const patchCheckoutDate = createAction(
  `[Menus container] Patch a checkout date`,
  props<{
    payload: { data: Partial<ManagableAccess>; id: number; url: string };
  }>(),
);

export const setNextMenu = createAction(
  `[Menus container] Set next menu`,
  props<{ menu: Menu }>(),
);

export const setPrevMenu = createAction(
  `[Menus container] Set previous menu`,
  props<{ menu: Menu }>(),
);
