import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { showDialogToVerifyPassword } from 'src/app/redux/authentication/authentication.actions';
import { selectIsFullyLogged } from 'src/app/redux/authentication/authentication.selectors';
import { selectTerminal } from 'src/app/redux/user/user.selectors';
import { UtilsService } from 'src/app/shared/services/utils.service';

const authenticationRoutes = [
  '/login',
  '/registration',
  '/reset-password',
  '/reset',
];

export const loginGuard: CanActivateFn = (
  _route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const router = inject(Router);
  const store = inject(Store);
  const utilsService = inject(UtilsService);

  const isLogged$ = store.pipe(select(selectIsFullyLogged), take(1));
  const isTerminal$ = store.pipe(select(selectTerminal), take(1));

  const handleAuthRoutes = (
    state: RouterStateSnapshot,
    isLogged: boolean,
  ): boolean => {
    if (isLogged) {
      router.navigate(['menus'], {
        queryParams: state.root.queryParams,
      });
      return false;
    }
    return true;
  };

  const handleTerminalSettings = (): false => {
    utilsService.enableAutofocus.next(false);
    store.dispatch(showDialogToVerifyPassword());
    return false;
  };

  const handleUnauthenticated = (state: RouterStateSnapshot): false => {
    const queryParams = {};
    if (state.root.queryParams.key && state.root.queryParams.auth_room) {
      queryParams['key'] = state.root.queryParams.key;
      queryParams['auth_room'] = state.root.queryParams.auth_room;
    }
    router.navigate(['login'], { queryParams });
    return false;
  };

  const forceToSettings =
    router.getCurrentNavigation().extras?.state?.forceToSettings;
  if (forceToSettings) return true;
  return isLogged$.pipe(
    switchMap((isLogged) => of(isLogged).pipe(withLatestFrom(isTerminal$))),
    map(([isLogged, isTerminal]) => {
      if (authenticationRoutes.some((route) => state.url.startsWith(route))) {
        return handleAuthRoutes(state, isLogged);
      }
      if (isTerminal && state.url === `/settings`) {
        return handleTerminalSettings();
      }
      if (!isLogged) return handleUnauthenticated(state);
      return isLogged;
    }),
  );
};
