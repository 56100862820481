import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { UnknownManagableItem } from '../../shared/models/misc';
import { redirectToOrders } from '../menus/menus.actions';
import { Order, RepeatOrder } from './../../shared/models/orders';
import {
  clearOrdersPage,
  ordersSelectionStateChanged,
  repeatOrdersFetched,
  sendOrdersAndNext,
  sendOrdersAndPrev,
  setCreatedRepeatOrder,
  setDeletedRepeatOrder,
  setManagableItem,
  setMenuDiets,
  setMenuLanguage,
  setOrders,
  setOrdersCountIncrement,
  setOrdersDoneCount,
  setOrdersCount as setOrdersRemainingCount,
  setProductSheetLoading,
  setProductSheetSource,
  setRepeatOrdersManage,
  setSaveOrderButtonState,
  setSource,
  setUpdatedRepeatOrder,
  updateRepeatOrders,
} from './orders.actions';

export const ordersFeatureKey = 'orders';

export interface OrdersState {
  source: string | null;
  diets: string[];
  orders: EntityState<Order>;
  ordersSelectionChanged: boolean;
  routerExtras: UnknownManagableItem;
  repeatOrders: RepeatOrder[];
  repeatOrdersManage: EntityState<RepeatOrder>;
  ordersFetched: boolean;
  save_order_spinner: boolean;
  productSheetLoading: boolean;
  productSheetSource: string;
  menuLanguage: string;
  ordersRemainingCount: number;
  ordersDoneCount: number;
}

export const adapter: EntityAdapter<Order> = createEntityAdapter<Order>();
export const adapterRepeat: EntityAdapter<RepeatOrder> =
  createEntityAdapter<RepeatOrder>();

export const initialState: OrdersState = {
  orders: adapter.getInitialState<Order>(null),
  source: null,
  diets: [],
  ordersSelectionChanged: null,
  routerExtras: null,
  repeatOrders: null,
  repeatOrdersManage: adapterRepeat.getInitialState<RepeatOrder>(null),
  ordersFetched: false,
  save_order_spinner: false,
  productSheetLoading: false,
  productSheetSource: null,
  menuLanguage: null,
  ordersRemainingCount: null,
  ordersDoneCount: null,
};

const _ordersReducer = createReducer<OrdersState>(
  initialState,
  on(sendOrdersAndPrev, sendOrdersAndNext, (state) => ({
    ...state,
    source: null,
  })),
  on(repeatOrdersFetched, (state, { repeatOrders }) => ({
    ...state,
    repeatOrders,
  })),
  on(setRepeatOrdersManage, (state, { repeatOrders }) => ({
    ...state,
    repeatOrdersManage: adapterRepeat.setAll(
      repeatOrders,
      state?.repeatOrdersManage,
    ),
  })),
  on(setCreatedRepeatOrder, (state, { repeatOrder }) => ({
    ...state,
    repeatOrdersManage: adapterRepeat.addOne(
      repeatOrder,
      state?.repeatOrdersManage,
    ),
  })),
  on(setUpdatedRepeatOrder, (state, { repeatOrder }) => ({
    ...state,
    repeatOrdersManage: adapterRepeat.updateOne(
      repeatOrder,
      state?.repeatOrdersManage,
    ),
  })),
  on(setDeletedRepeatOrder, (state, { id }) => ({
    ...state,
    repeatOrdersManage: adapterRepeat.removeOne(id, state?.repeatOrdersManage),
  })),
  on(setSource, (state, { source }) => ({
    ...state,
    source,
  })),
  on(setProductSheetSource, (state, { productSheetSource }) => ({
    ...state,
    productSheetSource,
  })),
  on(setSaveOrderButtonState, (state, { save_order_spinner }) => ({
    ...state,
    save_order_spinner,
  })),
  on(setProductSheetLoading, (state, { productSheetLoading }) => ({
    ...state,
    productSheetLoading,
  })),
  on(setMenuDiets, (state, { selectedDiets }) => ({
    ...state,
    diets: selectedDiets,
  })),
  on(setOrders, (state, { orders }) => ({
    ...state,
    orders: adapter.setAll(orders, state?.orders),
    ordersFetched: true,
  })),
  on(ordersSelectionStateChanged, (state, { state: payload }) => ({
    ...state,
    ordersSelectionChanged: payload,
  })),
  on(redirectToOrders, (state) => ({
    ...state,
  })),
  on(setManagableItem, (state, { item }) => ({ ...state, routerExtras: item })),
  on(setMenuLanguage, (state, { language }) => ({
    ...state,
    source: null,
    menuLanguage: language,
  })),
  on(setOrdersRemainingCount, (state, { count }) => ({
    ...state,
    ordersRemainingCount: count,
  })),
  on(setOrdersDoneCount, (state, { count }) => ({
    ...state,
    ordersDoneCount: count,
  })),
  on(setOrdersCountIncrement, (state) => ({
    ...state,
    ordersRemainingCount: state.ordersRemainingCount - 1,
    ordersDoneCount: state.ordersDoneCount + 1,
  })),
  on(updateRepeatOrders, (state, { repeatOrders }) => ({
    ...state,
    repeatOrders: state.repeatOrders?.map((repeatOrder) => {
      if (
        repeatOrders.some(
          (existingOrder) =>
            existingOrder.item_baselang === repeatOrder.item_baselang,
        )
      ) {
        return repeatOrders.find(
          (existingOrder) =>
            existingOrder.item_baselang === repeatOrder.item_baselang,
        );
      }
      return repeatOrder;
    }),
  })),
  on(clearOrdersPage, () => initialState),
);

export function ordersReducer(state: OrdersState, action: Action) {
  return _ordersReducer(state, action);
}
