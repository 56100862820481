import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectCurrentMenu, selectPickedDate } from '../menus/menus.selectors';
import { selectRouteParams } from '../router/router.selectors';
import {
  selectIsAgent,
  selectIsManager,
  selectUser,
} from '../user/user.selectors';
import {
  OrdersState,
  adapter,
  adapterRepeat,
  ordersFeatureKey,
} from './orders.reducer';

export const selectState = createFeatureSelector<OrdersState>(ordersFeatureKey);

const { selectAll } = adapter.getSelectors();
const selectRepeatAll = adapterRepeat.getSelectors().selectAll;

export const selectCurrentMenuSource = createSelector(
  selectState,
  (orders: OrdersState) => orders?.source,
);

export const selectCurrentMenuDiets = createSelector(
  selectState,
  (orders: OrdersState) => orders?.diets,
);

export const selectSaveOrderButtonState = createSelector(
  selectState,
  (orders: OrdersState) => orders?.save_order_spinner,
);

export const selectProductSheetLoadingState = createSelector(
  selectState,
  (orders: OrdersState) => orders?.productSheetLoading,
);

export const selectProductSheetSource = createSelector(
  selectState,
  (orders: OrdersState) => orders?.productSheetSource,
);

export const selectOrders = createSelector(selectState, (orders: OrdersState) =>
  selectAll(orders?.orders),
);

export const selectOrdersWereFetched = createSelector(
  selectState,
  (state) => state.ordersFetched,
);

export const selectOrdersSelectionState = createSelector(
  selectState,
  (state) => state?.ordersSelectionChanged,
);

export const selectOrdersRouterExtras = createSelector(
  selectState,
  (state) => state?.routerExtras,
);

export const selectFirstOrderOfMenu = createSelector(selectState, (state) =>
  selectAll(state?.orders)?.length ? selectAll(state?.orders)?.[0] : null,
);

export const selectRepeatOrders = createSelector(
  selectState,
  (state) => state?.repeatOrders,
);

export const selectRepeatOrdersManage = createSelector(selectState, (state) =>
  selectRepeatAll(state?.repeatOrdersManage),
);

export const selectShowHiddenOrders = createSelector(
  selectIsManager,
  selectIsAgent,
  selectUser,
  selectOrdersRouterExtras,
  (isManager, isAgent, user, extras) => {
    const agentAdminItems = user?.location
      ? user?.location_detail?.agent_show_admin_items
      : user?.organisation?.agent_show_admin_items;
    return (isManager && extras) || (isAgent && extras && agentAdminItems);
  },
);

export const selectDate = createSelector(
  selectCurrentMenu,
  selectPickedDate,
  selectRouteParams,
  (menu, pickedDate, params) => {
    return menu?.date || pickedDate || (params?.date as string);
  },
);

export const selectMenuLanguage = createSelector(
  selectState,
  (state) => state?.menuLanguage,
);

export const selectOrdersRemainingCount = createSelector(
  selectState,
  (state) => state?.ordersRemainingCount,
);

export const selectOrdersDoneCount = createSelector(
  selectState,
  (state) => state?.ordersDoneCount,
);
