<form class="form" [formGroup]="formGroup" serverFormError [formId]="formId">
  <ng-content select=".fields"></ng-content>
  @if (formGroup?.errors) {
    <div class="form-errors">
      @for (error of formGroup?.errors | keyvalue; track error) {
        @if (error.key === 'error') {
          <mat-error
            [innerHtml]="error.value"
            (click)="handleLinks($event)"
          ></mat-error>
        }
      }
    </div>
  }
  <ng-content select=".submit-button"></ng-content>
</form>
